import React from "react";
import cn from "classnames";
import { Typography } from "../../Common/Typography/Typography";
import { statusAppointments, visitNoteStatuses } from "../../../constants";
import { DECLINED } from "../../../constants";
import { Group } from "@material-ui/icons";
import { Tooltip } from "../../Common/Tooltip";
import { getTimeInFormat12 } from "../../../utils/date";

export const CalendarEventShift = ({ hideTooltip, ...props }) => {
  const membersAmount = props.event.data.requests_in_group;
  const isGroup = membersAmount >= 1;
  const isMultiple = props.event.data.is_grouped;
  const requestsAmount = props.event.data.requests_count;
  const isDeclined =
    props.event.data.service_request_status &&
    props.event.data.service_request_status.toLowerCase() === DECLINED;

  const date = `${getTimeInFormat12(props.event.start)} - ${getTimeInFormat12(props.event.end)}`;
  return (
    <Tooltip title={date} hide={hideTooltip}>
      <div
        onClick={() => {
          props.onClick(props.event.data);
        }}
        className={cn("shedule-container-event-shift", {
          "shedule-container-event-shift_accepted":
            props.appointment &&
            !isDeclined &&
            props.event.data.status === statusAppointments.accepted,
          "shedule-container-event-shift_assigned":
            (props.appointment &&
              !isDeclined &&
              props.event.data.status === statusAppointments.assigned) ||
            !props.appointment,
          "shedule-container-event-shift_no-doctor":
            props.appointment &&
            !isDeclined &&
            props.event.data.status === null,
          "shedule-container-event-shift_not-selected":
            props.selectedEvent.uuid &&
            props.selectedEvent.uuid !== props.event.data.uuid,
          "shedule-container-event-shift_declined": isDeclined,
        })}
      >
        {props.appointment ? (
          <>
            {isMultiple ? (
              <div className="shedule-container-event-shift_amount">
                <span className="shedule-container-event-shift_amount-wrapper">
                  {`${requestsAmount} requests`}
                </span>
              </div>
            ) : (
              <>
                <div className="shedule-container-event-shift_header">
                  {isGroup && (
                    <div className="shedule-container-event-shift_icon">
                      <Group style={{ fontSize: 16 }} />
                      <span>({membersAmount})</span>
                    </div>
                  )}
                  <span className="shedule-container-event-shift_status">
                    {
                      visitNoteStatuses[
                        props?.event.data?.service_request_status?.toLowerCase()
                      ]?.statusView
                    }
                  </span>
                </div>
                <span className="shedule-container-event-shift_patient">
                  {props.event.data.patient_name}
                </span>
              </>
            )}
          </>
        ) : (
          <>
            {!!props.event.multipleUuid ? (
              <>
                <div className="shedule-container-event-shift_header">
                  {isGroup && (
                    <div className="shedule-container-event-shift_icon">
                      <Group style={{ fontSize: 16 }} />
                      <span>({membersAmount})</span>
                    </div>
                  )}
                  <span className="shedule-container-event-shift_status">
                    {props.event.data.service_request_status}
                  </span>
                </div>
                <span className="shedule-container-event-shift_patient">
                  {props.event.data.patient_name}
                </span>
              </>
            ) : (
              <div className="shedule-container-event-shift_shift-data">
                <span className="shedule-container-event-shift_patient">
                  {`${props.event.data.doctor_info?.first_name} ${props.event.data.doctor_info?.last_name}`}
                </span>
                {props.event.data.is_backup === "backed_up" && (
                  <Typography variant="h6" text="Back up" className="" />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
};
