import React from "react";
import { PickerDate } from "../DateTimePicker/DatePicker";
import "./style.scss";
import cn from "classnames";

export const RangeDatePicker = ({
  dateFrom,
  dateTo,
  onChange,
  maxDateTo,
  maxDateFrom,
  disabled,
}) => {
  return (
    <div
      className={cn(
        "custom-date-range-picker",
        disabled && "custom-date-range-picker_disabled"
      )}
    >
      <div className="custom-date-range-picker_container-date-from">
        <div className="custom-date-range-picker_container-date-from_label">
          Start
        </div>

        <PickerDate
          maxDate={dateTo}
          className="custom-date-range-picker_container-date-from_from"
          popperPlacement="top"
          selected={dateFrom}
          onChange={e => {
            onChange("dateFrom", e);
          }}
          disabled={disabled}
          calendar
          selectsStart
          startDate={dateFrom}
          endDate={dateTo}
        />
      </div>
      <div className="custom-date-range-picker_container-date-to">
        <div className="custom-date-range-picker_container-date-to_label">
          Finish
        </div>
        <PickerDate
          disabled={disabled}
          className="custom-date-range-picker_container-date-to_to"
          popperPlacement="top"
          selected={dateTo}
          onChange={e => {
            onChange("dateTo", e);
          }}
          selectsEnd
          startDate={dateFrom}
          endDate={dateTo}
          maxDate={maxDateTo}
          minDate={dateFrom}
        />
      </div>
    </div>
  );
};
