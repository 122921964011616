import React from "react";
import "./style.scss";
import { RequestDetailsOther } from "../PastMedicalHistory/RequestDetailsOther";
import { Typography } from "../../../../Common/Typography/Typography";
import { colors } from "../../../../../assets/colors/colors";
import { FollowAttachementNotes } from "../PastMedicalHistory/FollowAttachementNotes";
import ErrorBoundary from "../../../../ErrorBoundary";
import { formatViewDate, getTimeInFormat12 } from "utils/date";

export const AllRequestDetails = props => {
  const date = formatViewDate(props.dataRequest?.data?.application_time);
  const timeFrom = getTimeInFormat12(
    props.dataRequest?.data?.application_can_start_at
  );
  const timeTo = getTimeInFormat12(props.dataRequest?.data?.application_time);
  return (
    <div className="all-request-details">
      {date && (
        <div className="date-time__wrapper date-time__container">
          <div className="date-time__container">
            <Typography
              color={colors.greyMiddle}
              className="date-time__label"
              variant="p"
              text="Date and time: "
            />
            <Typography
              color={colors.black}
              className="date-time__elem"
              variant="p"
              text={date}
            />
          </div>
          <div className="date-time__container">
            <Typography
              color={colors.black}
              className="date-time__elem"
              variant="p"
              text={`${timeFrom} - ${timeTo}`}
            />
          </div>
        </div>
      )}
      {props.dataRequest?.data?.uuid && <RequestDetailsOther {...props} />}
      <ErrorBoundary>
        <FollowAttachementNotes {...props} />
      </ErrorBoundary>
    </div>
  );
};
