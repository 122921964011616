import createReducer from "../createReducer.js";
import {
  SET_LOCATION_DATA,
  SET_SPECIALITY_DATA,
  SET_CALENDAR_EVENTS,
  SET_DETAILS_EVENT,
  SET_STATUS_ARRIVE,
  SET_DASHBOARD_DOCTOR_DATA,
  CLEAR_ALL_DATA_DOCTOR,
  SET_PCP_DOCTORS,
  SET_TYPES_DOCTOR,
  SET_FOLLOW_UPS,
  SET_ATTACHMENTS,
  SET_TYPES_DEGREE,
  SET_LIST_REQUEST_PATIENT,
  SET_ADDITIONAL_INFO,
  SET_PMH_INITIAL_INFO,
  SET_ATTACHMENT_PMH,
  SET_DATA_EXCUSE,
  SET_TEMPLATES,
  SET_DATA_TEMPLATE,
  SET_FSH_OPTIONS,
  SET_CPT_CODE,
  SET_DB_DATA,
  SET_DISCHARGE_DATA,
  SET_ICD_LIST,
  SET_RECEIPT_DATA,
  SET_DEFAULT_VALUE_VISIT_NOTES,
  SET_DEFAULT_VALUE_REQUEST,
  UPDATE_STSTUS_NOTES,
  CLEAR_DATA_AFTER_DELETE_VISIT_NOTE,
  SET_SIGNATURE,
  SET_DATA_COVID,
  UPDATE_REQYEST_STATE_NOTE_DOCTOR
} from "./actionTypes";

const initialState = {
  signature: null,
  dataNotification: {
    patient: {},
    location: {},
    symptoms: [],
    client_user_info: {}
  },
  addEditDoctor: {
    locations: [],
    speciality: []
  },

  calendar: {
    events: []
  },
  dashboard: {
    notesChar: [],
    visits: {},
    patientSatisfaction: {},
    responseTime: {}
  },
  pcpDoctors: [],
  typesDoctors: [],
  followUps: [],
  attachments: [],
  attachmentsPMH: [],
  degree: [],
  listLastRequests: [],
  additionalInfoRequest: [],
  pmhDetailInfo: null,
  familySocialHistoryOptions: [],
  templates: [],
  dataTemplate: null,
  excuse: null,
  covid: null,
  cptCodes: [],
  dbDataTemplate: null,
  dischargeData: {},
  icdList: [],
  receiptData: {}
};

function setDefaultValueRequest(state) {
  return {
    ...state,
    excuse: null,
    dataTemplate: null,
    covid: null,
    pmhDetailInfo: null,
    additionalInfoRequest: [],
    listLastRequests: [],
    attachmentsPMH: [],
    dataNotification: {
      patient: {},
      location: {},
      symptoms: [],
      client_user_info: {}
    },
    attachments: [],
    followUps: [],
    pcpDoctors: [],
    dbDataTemplate: null
  };
}

function updateStatusNotes(state, { payload }) {
  const documents = {
    note: state.dataTemplate && { ...state.dataTemplate },
    receipt: state.receiptData && { ...state.receiptData },
    discharge: { ...state.dischargeData },
    excuse: state.excuse ? { ...state.excuse } : null,
    covid: state.covid ? { ...state.covid } : null
  };
  payload.forEach(item => {
    documents[item].status = "sent";
  });
  // if(dataTemplate.hasOwnProperty('status')) dataTemplate.status = payload.find(i => i === 'note') ? 'sent' : state.dataTemplate.status
  // if(receiptData.hasOwnProperty('status')) receiptData.status = payload.find(i => i === 'receipt') ? 'sent' : state.receiptData.status
  // if(dischargeData.hasOwnProperty('status')) dischargeData.status = payload.find(i => i === 'discharge') ? 'sent' : state.dischargeData.status
  return {
    ...state,
    ...documents,
    receiptData: documents.receipt,
    dischargeData: documents.discharge,
    dataTemplate: documents.note
  };
}

function setDefaultValueVisitNotes(state) {
  return { ...state, receiptData: {}, dischargeData: {} };
}

function cleatDataVisitNoteAfterDelete(state) {
  return {
    ...state,
    receiptData: {},
    dischargeData: {},
    dbDataTemplate: null,
    excuse: null,
    covid: null,
    dataTemplate: null
  };
}

function setSignature(state, { payload }) {
  return { ...state, signature: payload };
}

function setDataTemplate(state, { payload }) {
  return { ...state, dataTemplate: payload };
}

function setExcuse(state, { payload }) {
  return { ...state, excuse: payload };
}

function setCovid(state, { payload }) {
  return { ...state, covid: payload };
}

function setTemplates(state, { payload }) {
  return { ...state, templates: payload };
}

function setListRequests(state, { payload }) {
  return { ...state, listLastRequests: payload };
}

function setDegree(state, { payload }) {
  return { ...state, degree: payload };
}

function setFollowUps(state, { payload }) {
  return { ...state, followUps: payload };
}

function setAttachments(state, { payload }) {
  return { ...state, attachments: payload };
}

function setPCPDoctors(state, { payload }) {
  return { ...state, pcpDoctors: payload };
}

function setTypesDoctors(state, { payload }) {
  return { ...state, typesDoctors: payload };
}

function setDashboardData(state, { payload }) {
  return { ...state, dashboard: payload };
}

const setEvents = (state, { payload }) => ({
  ...state,
  calendar: { ...state.calendar, events: payload }
});

function updateRequestOnList(state, { payload }) {
  const func = i =>
    i.uuid === payload.uuid
      ? {
          ...i,
          service_request_chat: {
            ...i.service_request_chat,
            has_unread_messages: payload.mess
          }
        }
      : i;
  const funcAll = i => ({
    ...i,
    service_request_chat: {
      ...i.service_request_chat,
      has_unread_messages: false
    }
  });
  if (payload.allRead) {
    return {
      ...state,
      calendar: {
        ...state.calendar,
        events: state?.calendar?.events?.map(funcAll)
      }
    };
  } else {
    return {
      ...state,
      calendar: {
        ...state.calendar,
        events: state?.calendar?.events?.map(func)
      }
    };
  }
}

const clearDataDoctor = () => ({
  ...initialState
});

function setLocationOptions(state, { payload }) {
  return {
    ...state,
    addEditDoctor: {
      ...state.addEditDoctor,
      locations: payload
    }
  };
}

function setSpecialityOptions(state, { payload }) {
  return {
    ...state,
    addEditDoctor: {
      ...state.addEditDoctor,
      speciality: payload
    }
  };
}

function setDetailsInfo(state, { payload }) {
  if (payload.service_type === "covid") {
    payload.comment = "Covid-19 Test";
    payload.symptoms = [{ uuid: "testCovid", name: "Covid-19 Test" }];
  }
  return {
    ...state,
    dataNotification: payload
  };
}

function setStatusArrive(state, { payload }) {
  return {
    ...state,
    dataNotification: {
      ...state.dataNotification,
      status: payload.status,
      doctor_should_arrive_at: payload.arrive
    }
  };
}

function setAdditionalInfo(state, { payload }) {
  return {
    ...state,
    additionalInfoRequest: payload
  };
}

function setPMHinitialInfo(state, { payload }) {
  return {
    ...state,
    pmhDetailInfo: payload
  };
}

function setAttachmentPMH(state, { payload }) {
  return {
    ...state,
    attachmentsPMH: payload
  };
}

function setFSHoptions(state, { payload }) {
  return {
    ...state,
    familySocialHistoryOptions: payload
  };
}

function setCPDvalueCode(state, { payload }) {
  return {
    ...state,
    cptCodes: payload
  };
}

function setDBData(state, { payload }) {
  return {
    ...state,
    dbDataTemplate: payload
  };
}

function setDischargeData(state, { payload }) {
  return {
    ...state,
    dischargeData: payload
  };
}

function setICDlist(state, { payload }) {
  return {
    ...state,
    icdList: payload
  };
}

function setReceipt(state, { payload }) {
  return {
    ...state,
    receiptData: payload
  };
}

const doctorReducer = createReducer(initialState, {
  [SET_LOCATION_DATA]: setLocationOptions,
  [SET_SPECIALITY_DATA]: setSpecialityOptions,
  [SET_CALENDAR_EVENTS]: setEvents,
  [SET_DETAILS_EVENT]: setDetailsInfo,
  [SET_STATUS_ARRIVE]: setStatusArrive,
  [SET_DASHBOARD_DOCTOR_DATA]: setDashboardData,
  [CLEAR_ALL_DATA_DOCTOR]: clearDataDoctor,
  [SET_PCP_DOCTORS]: setPCPDoctors,
  [SET_TYPES_DOCTOR]: setTypesDoctors,
  [SET_FOLLOW_UPS]: setFollowUps,
  [SET_ATTACHMENTS]: setAttachments,
  [SET_TYPES_DEGREE]: setDegree,
  [SET_LIST_REQUEST_PATIENT]: setListRequests,
  [SET_ADDITIONAL_INFO]: setAdditionalInfo,
  [SET_PMH_INITIAL_INFO]: setPMHinitialInfo,
  [SET_ATTACHMENT_PMH]: setAttachmentPMH,
  [SET_TEMPLATES]: setTemplates,
  [SET_DATA_TEMPLATE]: setDataTemplate,
  [SET_DATA_EXCUSE]: setExcuse,
  [SET_DATA_COVID]: setCovid,
  [SET_FSH_OPTIONS]: setFSHoptions,
  [SET_CPT_CODE]: setCPDvalueCode,
  [SET_DB_DATA]: setDBData,
  [SET_DISCHARGE_DATA]: setDischargeData,
  [SET_ICD_LIST]: setICDlist,
  [SET_RECEIPT_DATA]: setReceipt,
  [SET_DEFAULT_VALUE_VISIT_NOTES]: setDefaultValueVisitNotes,
  [SET_DEFAULT_VALUE_REQUEST]: setDefaultValueRequest,
  [UPDATE_STSTUS_NOTES]: updateStatusNotes,
  [CLEAR_DATA_AFTER_DELETE_VISIT_NOTE]: cleatDataVisitNoteAfterDelete,
  [SET_SIGNATURE]: setSignature,

  [UPDATE_REQYEST_STATE_NOTE_DOCTOR]: updateRequestOnList
});

export default doctorReducer;
