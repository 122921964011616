import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./style.scss";
import { InputPicker } from "./InputPicker";
import { Typography } from "../Typography/Typography";
import { colors } from "../../../assets/colors/colors";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export class PickerDate extends Component {
  render() {
    let valueDate = this.props.selected && {
      m: month[this.props.selected.getMonth()]?.slice(0, 3),
      d: this.props.selected.getDate(),
      y: this.props.selected.getFullYear()
    };
    const CustomHeader = ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      label
    }) => (
      <div className="wrapper-custom-date-picker_header">
        <div className="wrapper-custom-date-picker_header_title">
          <Typography
            text={date.getFullYear()}
            variant="title"
            color={colors.core70}
          />
          <Typography
            variant="h2"
            text={`${moment(date).format("ddd")}, ${moment(date).format(
              "MMMM"
            )} ${date.getDate()}`}
            className='wrapper-custom-date-picker_header_title__date'
          />
        </div>
        <div className="wrapper-custom-date-picker_header_container">
          <div
            className="wrapper-custom-date-picker_header_container_arrowBack arrow"
            onClick={decreaseMonth}
          />
          <div className="wrapper-custom-date-picker_header_container_container-selectors">
            <span className="">
              {month[date.getMonth()]} {date.getFullYear()}
            </span>
          </div>
          <div
            className="wrapper-custom-date-picker_header_container_arrowNext arrow"
            onClick={increaseMonth}
          />
        </div>
      </div>
    );

    return (
      <div className="wrapper-custom-date-picker">
        {this.props.label && (
          <Typography
            text={this.props.label}
            variant="p"
            color={colors.darkMuted}
            className="wrapper-custom-date-picker_label"
          />
        )}
        <DatePicker
          // inline // open all time
          fixedHeight
          disabled={this.props.disabled}
          popperModifiers={{
            computeStyle: { gpuAcceleration: false }
          }}
          customInput={
            <InputPicker
              color={this.props.color}
              defaultValue="MM/DD/YYYY"
              calendar={this.props.calendar}
              value={
                valueDate ? `${valueDate.m} ${valueDate.d}, ${valueDate.y}` : ""
              }
            />
          }
          renderCustomHeader={props => (
            <CustomHeader {...props} label={this.props.label} />
          )}
          {...this.props}
        />
      </div>
    );
  }
}
